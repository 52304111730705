import * as React from "react"
import MyPageLayout from "../components/Layout/MyPageLayout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <MyPageLayout props="error">
    <main className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 my-auto py-16 sm:py-32">
            <p className="text-sm font-semibold text-logo-600 uppercase tracking-wide">404 error</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Side ikke funnet</h1>
            <p className="mt-2 text-base text-gray-500">Beklager, vi kan ikke finne siden du leter etter.</p>
            <div className="mt-6">
              <a href="/" className="text-base font-medium text-link-500 hover:text-link-400">
                Gå tilbake til forsiden<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </main>
  </MyPageLayout>
)

export default NotFoundPage
